<template>
  <div class="mb-5">
    <v-row v-if="$vuetify.breakpoint.lgAndUp" no-gutters>
      <v-select
        v-model="idSchoolYear"
        :items="listSchoolYear"
        :label="$t('master_data.student.school_year')"
        :item-text="item => `${item.start_year} / ${item.end_year}`"
        :loading="loadingSchoolYear"
        item-value="id"
        outlined
        hide-details
        dense
        class="mr-2 select-225 d-inline-block"
      >
        <template #item="{ item }">
          <span class="caption">
            {{ item.start_year }} / {{ item.end_year }}, semester
            {{ item.semester }}
          </span>
          <v-tooltip right>
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                :color="schoolYearInfo(item.status).color"
                small
                class="ml-1"
                >{{ schoolYearInfo(item.status).name }}</v-icon
              >
            </template>
            <span>{{ schoolYearInfo(item.status).info }}</span>
          </v-tooltip>
        </template>
      </v-select>
      <v-select
        v-model="idGrade"
        :items="listGrade"
        :label="$t('schedule.choose_grade')"
        :loading="loadingGrade"
        item-value="id"
        item-text="grade"
        outlined
        hide-details
        dense
        class="mr-2 select-200 d-inline-block"
        @change="changeGrade"
      />
      <v-select
        v-model="modelClass"
        :items="listClass"
        :label="$t('academic.schedule_data.type_of_class')"
        :loading="loadingClass"
        :disabled="loadingClass || !idGrade"
        item-value="id"
        item-text="name"
        class="select-200 d-inline-block"
        outlined
        hide-details
        dense
      />
      <v-spacer />
      <v-text-field
        v-model="search"
        :label="$t('app.search')"
        :class="$vuetify.breakpoint.xsOnly && 'mt-2'"
        outlined
        dense
        hide-details
        clearable
        style="max-width: 250px"
        prepend-inner-icon="mdi-magnify"
      />
      <v-col cols="12">
        <v-divider class="my-4" />
      </v-col>
      <v-col cols="12" sm="8">
        <v-btn
          class="gradient-primary caption mr-2"
          depressed
          dark
          dense
          @click="$router.push({ path: 'question-bank' })"
          >{{ $t("e_test.question_bank")
          }}<v-icon class="ml-2">mdi-file-outline</v-icon></v-btn
        >
        <v-btn
          class="gradient-primary caption mr-2"
          depressed
          dark
          dense
          @click="toggleDialog"
          >{{ $t("e_test.code_of_conduct")
          }}<v-icon class="ml-2">mdi-pencil</v-icon></v-btn
        >
      </v-col>
      <v-col cols="12" sm="4" class="justify-end d-flex">
        <v-btn
          class="mr-2"
          color="primary"
          outlined
          depressed
          dense
          @click="downloadTemplate()"
          >Template data excel<v-icon class="ml-2"
            >mdi-file-excel</v-icon
          ></v-btn
        >
      </v-col>
    </v-row>

    <div v-else class="align-center">
      <div class="d-flex align-center">
        <v-btn color="primary" @click="sheet = !sheet" depressed
          ><v-icon>mdi-filter-outline</v-icon></v-btn
        >
        <div class="d-inline-block font-weight-bold ml-2">
          {{ count + " " + $t("e_test.question") }}
        </div>
        <v-spacer />
        <v-speed-dial
          v-model="menuSubject"
          direction="bottom"
          transition="slide-y-reverse-transition"
          style="z-index: 5"
        >
          <template v-slot:activator>
            <v-btn v-model="menuSubject" depressed color="primary" dark>
              <v-icon v-if="menuSubject">mdi-close</v-icon>
              <v-icon v-else>mdi-menu-down-outline</v-icon>
            </v-btn>
          </template>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                depressed
                fab
                dense
                small
                @click="downloadTemplate()"
              >
                <v-icon color="primary">mdi-cloud-download</v-icon>
              </v-btn>
            </template>
            <span>Template data excel</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                depressed
                fab
                dense
                small
                @click="$router.push({ path: 'question-bank' })"
              >
                <v-icon color="primary">mdi-file-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("e_test.question_bank") }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" depressed fab dense small @click="toggleDialog">
                <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("e_test.code_of_conduct") }}</span>
          </v-tooltip>
        </v-speed-dial>
      </div>
      <v-text-field
        v-model="search"
        :label="$t('app.search')"
        class="mt-3"
        outlined
        dense
        hide-details
        clearable
        prepend-inner-icon="mdi-magnify"
      />
    </div>

    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center pa-6">
        <v-row justify="center">
          <v-col cols="12" sm="4">
            <v-select
              v-model="idSchoolYear"
              :items="listSchoolYear"
              :label="$t('master_data.student.school_year')"
              :item-text="
                item =>
                  `${item.start_year} / ${item.end_year}, Semester ${item.semester}`
              "
              :loading="loadingSchoolYear"
              item-value="id"
              outlined
              hide-details
              class="mb-2"
              dense
            >
              <template #item="{ item }">
                <span class="caption">
                  {{ item.start_year }} / {{ item.end_year }}, semester
                  {{ item.semester }}
                </span>
                <v-tooltip right>
                  <template #activator="{ on }">
                    <v-icon
                      v-on="on"
                      :color="schoolYearInfo(item.status).color"
                      small
                      class="ml-1"
                      >{{ schoolYearInfo(item.status).name }}</v-icon
                    >
                  </template>
                  <span>{{ schoolYearInfo(item.status).info }}</span>
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="idGrade"
              :items="listGrade"
              :label="$t('schedule.choose_grade')"
              :loading="loadingGrade"
              item-value="id"
              item-text="grade"
              outlined
              hide-details
              class="mb-2"
              dense
              @change="changeGrade"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="modelClass"
              :items="listClass"
              :label="$t('academic.schedule_data.type_of_class')"
              :loading="loadingClass"
              :disabled="loadingClass || !idGrade"
              item-value="id"
              item-text="name"
              outlined
              hide-details
              class="mb-2"
              dense
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
    <CodeOfConduct :open="dialog" isGlobal @toggle="toggleDialog" />
  </div>
</template>

<script>
import {
  get_class_list,
  get_school_year_list,
  get_grade_list
} from "@/api/admin/schoolClass";
export default {
  props: {
    idClass: Number,
    count: Number
  },
  created() {
    this.getSchoolYear();
    this.getGrade();
  },
  components: {
    CodeOfConduct: () => import("./components/CodeOfConduct")
  },
  watch: {
    search(newValue) {
      this.searching(newValue);
    }
  },
  computed: {
    modelClass: {
      set(newValue) {
        this.setClass(newValue);
      },
      get() {
        return this.idClass;
      }
    }
  },
  data() {
    return {
      menuSubject: false,
      search: "",
      idSchoolYear: 0,
      listSchoolYear: [],
      loadingSchoolYear: false,
      loadingGrade: false,
      listGrade: [],
      idGrade: 0,
      listClass: [],
      loadingClass: false,
      sheet: false,
      dialog: false,
      typeList:
        this.$store.getters.g_role_type === "TEACHER" ? "homeroom_teacher" : ""
    };
  },
  methods: {
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    },
    searching(value) {
      this.$emit("search", value);
    },
    downloadTemplate() {
      window.open(process.env.VUE_APP_URL_EXCEL_MASTER_QUESTION, "_self");
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    setClass(value) {
      this.$emit("setIdClass", value);
    },
    async getSchoolYear() {
      const res = await get_school_year_list();
      if (res.data) {
        const data = res.data.data;
        this.listSchoolYear = data;
        this.idSchoolYear = data.find(item => item.status === 1)?.id;
      }
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    },
    getGrade() {
      this.loadingGrade = true;
      get_grade_list({ type: this.typeList })
        .then(res => {
          if (res.data.code == 1) {
            let r = res.data.data;
            this.listGrade = r;
            if (this.$route.query.grade) {
              this.idGrade = parseInt(this.$route.query.grade);
              this.changeGrade(this.idGrade);
            }
          } else {
            this.snackBar(true, res.data.message);
          }
          this.loadingGrade = false;
        })
        .catch(err => {
          this.loadingGrade = false;
          this.snackBar(true, err);
        });
    },
    changeGrade(grade) {
      this.loadingClass = true;
      let idSchoolYear = 0;
      if (this.$route.query.schoolYear) {
        idSchoolYear = parseInt(this.$route.query.schoolYear);
      } else {
        idSchoolYear = this.idSchoolYear;
      }
      get_class_list({
        grade: [grade],
        school_year: [idSchoolYear],
        type: this.typeList.toUpperCase()
      })
        .then(res => {
          if (res.data.code == 1) {
            let r = res.data.data;
            this.listClass = r;
            if (this.$route.query.class) {
              this.modelClass = parseInt(this.$route.query.class);
            }
          } else {
            this.snackBar(true, res.data.message);
          }
          this.loadingClass = false;
        })
        .catch(err => {
          this.loadingClass = false;
          this.snackBar(true, err);
        });
    }
  }
};
</script>
